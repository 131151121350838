import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const DisLike = createSvgIcon(
  <svg viewBox="0 0 14 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1532 13.0942C13.1532 16.4545 10.4659 19.1786 7.15088 19.1786C5.77354 19.1786 2.41134 19.1786 0.801717 19.1786C0.326599 19.1786 -0.0438144 18.768 0.00419217 18.2953L1.07558 7.7312C1.11704 7.32147 1.46097 7.00977 1.8727 7.00977C3.16794 7.00977 5.8658 7.00977 7.15088 7.00977C10.4659 7.00977 13.1532 9.73384 13.1532 13.0942Z" fill="white" />
    <path d="M7.29196 3.45136C7.52771 0.317693 5.80777 -0.884477 5.35405 0.690646C4.90032 2.26563 4.81835 3.15824 3.85809 4.53398C2.96629 5.81143 0.742609 7.11202 1.18697 7.95381C1.63133 8.79559 6.05409 8.31994 6.05409 8.31994C6.05409 8.31994 7.05621 6.58502 7.29196 3.45136Z" fill="white" />
  </svg>,
  'DisLike'
);

export default DisLike;
