import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export const Person = createSvgIcon(
  <svg viewBox="0 0 35 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 20.2762C22.4004 20.2762 26.3729 16.3037 26.3729 11.4033C26.3729 6.50294 22.4004 2.5304 17.5 2.5304C12.5996 2.5304 8.62708 6.50294 8.62708 11.4033C8.62708 16.3037 12.5996 20.2762 17.5 20.2762Z"
      strokeWidth="3.54917"
      className="person"
    />
    <path
      d="M26.3729 23.8254H26.9976C28.2949 23.8258 29.5475 24.2999 30.52 25.1587C31.4925 26.0174 32.1179 27.2017 32.2787 28.4891L32.9726 34.0329C33.035 34.5323 32.9905 35.0393 32.842 35.5202C32.6935 36.001 32.4444 36.4449 32.1113 36.8221C31.7781 37.1994 31.3686 37.5015 30.9098 37.7084C30.4509 37.9153 29.9534 38.0222 29.4501 38.0221H5.54994C5.04664 38.0222 4.54907 37.9153 4.09025 37.7084C3.63143 37.5015 3.22187 37.1994 2.88873 36.8221C2.5556 36.4449 2.30651 36.001 2.15801 35.5202C2.00951 35.0393 1.96498 34.5323 2.02739 34.0329L2.71948 28.4891C2.88043 27.2011 3.50636 26.0163 4.47957 25.1575C5.45278 24.2986 6.70622 23.8249 8.0042 23.8254H8.62707"
      strokeWidth="3.54917"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="person"
    />
  </svg>,
  'Person'
);

export const NewPerson = createSvgIcon(
  <svg viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 19.1115C7 16.6984 8.69732 14.643 11.004 14.2627L11.2118 14.2284C13.0589 13.9239 14.9411 13.9239 16.7882 14.2284L16.996 14.2627C19.3027 14.643 21 16.6984 21 19.1115C21 20.1545 20.1815 21 19.1719 21H8.82813C7.81848 21 7 20.1545 7 19.1115Z"
      fill="white"
      className="person"
    />
    <path
      d="M18.0834 6.9375C18.0834 9.11212 16.2552 10.875 14 10.875C11.7449 10.875 9.91669 9.11212 9.91669 6.9375C9.91669 4.76288 11.7449 3 14 3C16.2552 3 18.0834 4.76288 18.0834 6.9375Z"
      fill="white"
      className="person"
    />
  </svg>,
  'Person'
);
