const Logo = () => (
  <svg
    className="logo"
    id="edmibckomkog1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    width="500"
    height="500"
  >
    <rect
      id="edmibckomkog2"
      width="500"
      height="500"
      rx="0"
      ry="0"
      transform="matrix(1 0 0 1 0 0.50000000000003)"
      fill="rgb(255,255,255, 0)"
      stroke="none"
      strokeWidth="1"
    />
    <path
      id="edmibckomkog3"
      d="M427,250.500000C427,339.694000,355.142000,412,266.500000,412C223.550000,412,108.437000,412,79.613700,412C76.058800,412,73.314000,408.927000,73.675300,405.390000L105.449000,94.390200C105.762000,91.328500,108.306000,89,111.384000,89C134.544000,89,226.333000,89,266.500000,89C355.142000,89,427,161.306000,427,250.500000Z"
      fill="rgb(190,59,33)"
      stroke="none"
      strokeWidth="1"
    />
    <path
      id="edmibckomkog4"
      d="M299,250.500000C299,275.629000,278.904000,296,254.114000,296C243.814000,296,218.671000,296,206.634000,296C203.081000,296,200.311000,292.930000,200.670000,289.395000L208.682000,210.395000C208.992000,207.331000,211.564000,205,214.643000,205C224.329000,205,244.504000,205,254.114000,205C278.904000,205,299,225.371000,299,250.500000Z"
      fill="rgb(255,255,255)"
      stroke="none"
      strokeWidth="1"
    />
    <path
      id="edmibckomkog5"
      d="M427,250.500000C427,339.694000,355.142000,412,266.500000,412C223.550000,412,108.437000,412,79.613700,412C76.058800,412,73.314000,408.927000,73.675300,405.390000L105.449000,94.390200C105.762000,91.328500,108.306000,89,111.384000,89C134.544000,89,226.333000,89,266.500000,89C355.142000,89,427,161.306000,427,250.500000Z"
      fill="rgb(36,130,50)"
      stroke="none"
      strokeWidth="1"
    />
    <g
      id="edmibckomkog6_tr"
      transform="translate(205.018097,290.857130) rotate(-6.826305)"
    >
      <path
        id="edmibckomkog6"
        d="M257.260000,268.499000C277.611000,280.249000,277.789000,295.941000,266.147000,292.106000C254.505000,288.271000,248.646000,285.016000,236.099000,285.150000C224.450000,285.275000,207.044000,293.531000,203.717000,287.239000C200.389000,280.946000,221.967000,255.629000,221.967000,255.629000C221.967000,255.629000,236.908000,256.749000,257.260000,268.499000Z"
        transform="translate(-207.003391,-285.986130)"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <path
      id="edmibckomkog7"
      d="M299,250.500000C299,275.629000,278.904000,296,254.114000,296C243.814000,296,218.671000,296,206.634000,296C203.081000,296,200.311000,292.930000,200.670000,289.395000L208.682000,210.395000C208.992000,207.331000,211.564000,205,214.643000,205C224.329000,205,244.504000,205,254.114000,205C278.904000,205,299,225.371000,299,250.500000Z"
      fill="rgb(255,255,255)"
      stroke="none"
      strokeWidth="1"
    />
    <g
      id="edmibckomkog8_tr"
      transform="translate(209.018097,213.502960) rotate(0)"
    >
      <path
        id="edmibckomkog8"
        d="M258.813000,246.465000C281.512000,240.383000,285.746000,225.272000,273.508000,225.963000C261.269000,226.654000,254.767000,228.282000,242.683000,224.905000C231.463000,221.770000,216.787000,209.290000,211.945000,214.507000C207.102000,219.724000,221.391000,249.763000,221.391000,249.763000C221.391000,249.763000,236.114000,252.548000,258.813000,246.465000Z"
        transform="translate(-211.018097,-213.502960)"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default Logo;
