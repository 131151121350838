import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Search = createSvgIcon(
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.31573 11.9887L2.04647 11.8198L1.31573 11.9887ZM1.31573 6.44938L2.04647 6.61824L1.31573 6.44938ZM16.7893 6.44938L17.5201 6.28052L16.7893 6.44938ZM16.7893 11.9887L17.5201 12.1575L16.7893 11.9887ZM11.781 17.0724L11.6073 16.3428L11.781 17.0724ZM6.324 17.0724L6.15028 17.802L6.324 17.0724ZM6.324 1.36565L6.15028 0.636047L6.324 1.36565ZM11.781 1.36565L11.9548 0.636047L11.781 1.36565ZM18.4657 19.8427C18.7564 20.1378 19.2313 20.1413 19.5264 19.8506C19.8214 19.56 19.825 19.0851 19.5343 18.79L18.4657 19.8427ZM2.04647 11.8198C1.65118 10.1092 1.65118 8.32883 2.04647 6.61824L0.584986 6.28051C0.138338 8.21333 0.138338 10.2247 0.584986 12.1575L2.04647 11.8198ZM16.0586 6.61825C16.4539 8.32884 16.4539 10.1092 16.0586 11.8198L17.5201 12.1575C17.9667 10.2247 17.9667 8.21333 17.5201 6.28052L16.0586 6.61825ZM11.6073 16.3428C9.92689 16.7429 8.17816 16.7429 6.49772 16.3428L6.15028 17.802C8.05916 18.2565 10.0459 18.2565 11.9548 17.802L11.6073 16.3428ZM6.49772 2.09525C8.17816 1.69514 9.92689 1.69514 11.6073 2.09525L11.9548 0.636047C10.0459 0.181539 8.05916 0.181539 6.15028 0.636047L6.49772 2.09525ZM6.49772 16.3428C4.29452 15.8182 2.56635 14.0695 2.04647 11.8198L0.584986 12.1575C1.23092 14.9527 3.38343 17.1432 6.15028 17.802L6.49772 16.3428ZM11.9548 17.802C14.7216 17.1432 16.8741 14.9527 17.5201 12.1575L16.0586 11.8198C15.5387 14.0695 13.8105 15.8182 11.6073 16.3428L11.9548 17.802ZM11.6073 2.09525C13.8105 2.61984 15.5387 4.36852 16.0586 6.61825L17.5201 6.28052C16.8741 3.4853 14.7216 1.29484 11.9548 0.636047L11.6073 2.09525ZM6.15028 0.636047C3.38343 1.29484 1.23092 3.4853 0.584986 6.28051L2.04647 6.61824C2.56635 4.36852 4.29452 2.61984 6.49772 2.09525L6.15028 0.636047ZM14.8009 16.1226L18.4657 19.8427L19.5343 18.79L15.8694 15.0699L14.8009 16.1226Z"
      fill="#7A7A7A"
    />
  </svg>,
  'Search'
);

export default Search;
